import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import { Select, FieldLabel } from '@meetup/swarm-components';
import SelectExample from '../../components/examples/select';
import PropsTable from '../../components/propstable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Select`}</h1>
    <hr></hr>
    <p>{`A wrapper for an html `}<inlineCode parentName="p">{`<select>`}</inlineCode>{` element with an optional `}<inlineCode parentName="p">{`<label>`}</inlineCode>{` tag and helper text.`}</p>
    <br />
    <br />
    <h3>{`Snippet`}</h3>
    <hr></hr>
    <p>{`Use this interactive snippet to try the component:`}</p>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<div style={{width: 200}}>
    <FieldLabel htmlFor="swarm-select" >Swarm Select</FieldLabel>
    <Select
        value="A"
        id="swarm-select"
        name="swarm-select"
        onChange={(e) => alert(e.target.value)}
    >
        <option value="A">
            A
        </option>
        <option value="B">
            B
        </option>
        <option value="C">
            C
        </option>
        <option value="D">
            D
        </option>
    </Select>
</div>
`}</code></pre>
    <br />
    <br />
    <h3>{`Props`}</h3>
    <hr></hr>
    <br />
    <PropsTable propMetaData={Select.__docgenInfo} mdxType="PropsTable" />
    <br />
    <br />
    <h3>{`a11y`}</h3>
    <hr></hr>
    <br />
    <p>{`The following keyboard interactions are used to control the select component.
You can also filter by typing letters, but this behavior varies by browser.
Some will filter as you type, like autocomplete. Others will only sort by first letter.
E.g., in a list of US States, hitting A then R may take you to Arizona, or it may
take you to Alabama and then Rhode Island.`}</p>
    <br />
    <p><strong parentName="p">{`Keyboard interactions:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`↑/↓`}</strong>{`       ... navigate between menu options`}</li>
      <li parentName="ul"><strong parentName="li">{`Spacebar`}</strong>{`  ... expand`}</li>
    </ul>
    <br />
See: <a href="https://webaim.org/techniques/keyboard/">https://webaim.org/techniques/keyboard/</a>
    <br />
    <br />
    <h3>{`Examples`}</h3>
    <hr></hr>
    <br />
    <SelectExample mdxType="SelectExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      